@import url('https://fonts.googleapis.com/css?family=Rye|Open+Sans');

body {
    background-size:cover;
    background-repeat: no-repeat;
    background-color: #171615;
    background-position:center;
    margin: 0;
    padding: 0;
    background-attachment: fixed;
    font-family: 'Rye', sans-serif;
    color: #D2D9E2;
}